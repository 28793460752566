<template>
  <div id="miles-cards-list" class="miles-cards-list pd-20">
    <a-page-header class="mb-10 pd-0" sub-title="">
      <div slot="title">
        <h1>Controle de Estoque</h1>
      </div>
      <div slot="extra">
        <downloadExcel
          v-if="$root.isAdmin()"
          class="btn btn-default"
          :header="excelFile.header"
          :name="excelFile.fileName"
          :data="excelFile.data"
          :fields="excelFile.collumns"
          :footer="excelFile.footer"
          style="display: inline-block"
        >
          <img
            class="c-pointer ml-10 mr-20"
            src="@/assets/images/dashboard/excel.png" alt="img"
          />
        </downloadExcel>

        <a-button
          slot="tabBarExtraContent"
          icon="dollar"
          type="primary"
          ghost
          @click="$router.push('/buy-miles')"
        >
          Comprar Milhas
        </a-button>
      </div>
    </a-page-header>

    <a-tabs type="card" :default-active-key="activeTab" @change="onChangeTabs">
      <a-tab-pane key="available" tab=" DISPONÍVEIS" force-render> </a-tab-pane>
      <a-tab-pane key="blocked" tab=" BLOQUEADOS" force-render> </a-tab-pane>
      <a-tab-pane key="canceled" tab=" CANCELADOS" force-render> </a-tab-pane>
    </a-tabs>

    <a-row class="haya-panels" :gutter="20">
      <a-col :span="6">
        <div class="box">
          <div class="title"></div>
          <div class="total cblue">{{ totalMiles }}</div>
          <div class="txt">Total de Milhas</div>
          <div class="footer">R$ {{ totalMoney }}</div>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="box">
          <div class="title"></div>
          <div class="total cgreen">{{ totalAvailableMiles }}</div>
          <div class="txt">Disponível</div>
          <div class="footer">R$ {{ totalAvailableMoney }}</div>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="box">
          <div class="title"></div>
          <div class="total red">-{{ totalSpentMiles }}</div>
          <div class="txt">Utilizado</div>
          <div class="footer">R$ {{ totalMoneySpent }}</div>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="box">
          <div class="title"></div>
          <div class="total">{{ totalMilesCards }}</div>
          <div class="txt">Número de cartões</div>
          <div class="footer" style="color: transparent">.</div>
        </div>
      </a-col>
    </a-row>

    <a-collapse
      class="travel-filters expandable mt-20 mb-0"
      activeKey="0"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template slot="header">
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>

        <a-row class="mt-5" :gutter="20">
          <a-col :span="2">
            <div class="travel-input">
              <label for class="filled">ID do cartão</label>
              <a-input
                placeholder=""
                v-model="milesCards.filters.id"
                @change="getMilesCards()"
                @pressEnter="getMilesCards()"
              />
            </div>
          </a-col>

          <a-col :span="4">
            <div class="travel-input">
              <label for class="filled">Fornecedor</label>
              <a-auto-complete
                :data-source="
                  suppliers.map(({ first_name, last_name, id }) => ({
                    value: id,
                    text: `${id} - ${first_name} ${last_name}`,
                  }))
                "
                v-model="milesCards.filters.supplier"
                style="width: 100%"
                placeholder="Buscar fornecedores..."
                @search="searchSupplier"
                @change="getMilesCards()"
              />
            </div>
          </a-col>



          <a-col :span="4">
            <div class="travel-input">
              <label for class="filled">Cias</label>
              <a-select
                class="travel-input"
                placeholder="Selecione a cia aérea"
                mode="multiple"
                v-model="milesCards.filters.airlines.selected"
                show-search
                optionFilterProp="txt"
                style="width: 100%"
                @change="getMilesCards()"
              >
                <a-select-option
                  v-for="(item, index) of milesCards.filters.airlines.list"
                  :key="index"
                  :value="item.value"
                  :txt="item.label"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col :span="4">
            <div class="travel-input">
              <label for class="filled">Programas de Milhas</label>
              <a-select
                class="travel-input"
                placeholder="Selecione o programa"
                mode="multiple"
                v-model="milesCards.filters.milesPrograms.selected"
                show-search
                style="width: 100%"
                @change="getMilesCards()"
              >
                <a-select-option
                  v-for="(item, index) of milesCards.filters.milesPrograms.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col :span="5">
            <div class="travel-input">
              <label for class="filled">Período</label>
              <a-range-picker
                v-model="milesCards.filters.period.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
                @change="getMilesCards()"
              />
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>

    <a-table
      class="travel-table mt-10"
      rowKey="id"
      :columns="lastMilesCardsColumns"
      :data-source="milesCards.list"
      :pagination="false"
      :loading="milesCards.loading"
      :scroll="{ x: 1300 }"
      @change="milesCardsTableChange"
    >
      <template slot="id" slot-scope="record">
        {{ record.id }}
      </template>
      <template slot="is_vcm" slot-scope="record">
        <div v-html="checkIsVcmMiles(record.transactions)" />
      </template>
      <template slot="supplier" slot-scope="record">
        <div
          style="
            max-width: 180px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          "
        >
          {{ `${record.first_name} ${record.last_name}`.toUpperCase() }}
        </div>
      </template>
      <template slot="quantity" slot-scope="record">
        {{ record.id }}
      </template>
      <template slot="spent_miles" slot-scope="record">
        {{ record.spent_miles }}
      </template>
      <template slot="price_per_miles" slot-scope="record">
        R$ {{ record }}
      </template>
      <template slot="special_price" slot-scope="record">
        R$ {{ record }}
      </template>
      <template slot="selling_price" slot-scope="record">
        R$ {{ record }}
      </template>
      <template slot="expires_at" slot-scope="record">
        {{ formatDate(record) }}
      </template>
      <template slot="updated" slot-scope="record">
        {{ formatDateTime(record) }}
      </template>
      <template slot="created" slot-scope="record">
        {{ formatDateTime(record) }}
      </template>
      <span class="table-actions" slot="action" slot-scope="milesCard">
        <a-tooltip placement="top" title="Ver">
          <a class="view" @click="viewMilesCard(milesCard.id, 'card')">
            <a-icon slot="prefix" type="eye-svg" />
          </a>
        </a-tooltip>

        <a-divider
          v-if="$root.isAdmin() || $root.isMilesManager()"
          type="vertical"
          style="margin: 0 16px 0 12px"
        />

        <a-dropdown
          overlayClassName="actions-dropdown"
          v-if="$root.isAdmin() || $root.isMilesManager()"
          :trigger="['click']"
        >
          <a style="font-size: 15px; color: #aaa">
            <i class="fas fa-ellipsis-v"></i>
          </a>

          <a-menu slot="overlay">
            <a-menu-item @click="openSpecialPriceModal(milesCard)">
              <a-icon type="dollar" /> Preço Especial
            </a-menu-item>

            <a-menu-item
              @click="
                $router.push(`/miles-finances/payments/view/${milesCard.id}`)
              "
            >
              <a-icon type="calculator" /> Pagamentos
            </a-menu-item>

            <a-menu-item @click="view(milesCard.id)">
              <a-icon type="printer" /> Detalhamento
            </a-menu-item>

            <a-menu-item @click="viewMilesCard(milesCard.id, 'logs')">
              <a-icon type="profile" /> Histórico
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>

    <div class="a-center mt-30" style="padding-bottom: 100px">
      <a-pagination
        show-size-changer
        :default-current="milesCards.pagination.page"
        :page-size.sync="milesCards.pagination.perPage"
        :page-size-options="pageSizeOptions"
        :total="milesCards.pagination.total"
        @change="changePage"
        @showSizeChange="changePageSize"
      >
        <template slot="buildOptionText" slot-scope="props">
          <span v-if="props.value !== '99999'">{{ props.value }}/página</span>
        </template>
      </a-pagination>
    </div>

    <a-modal
      class="miles-card-modal"
      :visible="viewMilesCardDrawer"
      :footer="false"
      width="1000px"
      @cancel="closeMilesCardDrawer"
    >
      <MilesCardsModal
        v-if="viewMilesCardDrawer"
        :milesCard="milesCard"
        :editMilesCard="editMilesCard"
        :editCard="editCard"
        :activeMilesCardTab="activeMilesCardTab"
        @updateMilesCardsList="updateMilesCardsList"
      />
    </a-modal>

    <a-drawer
      placement="right"
      :closable="false"
      width="700px"
      :visible="editMilesCardDrawer"
      @close="closeMilesCardDrawer"
    >
      <template slot="title"
        >CARTÂO DE MILHAS {{ milesCard.miles_program }} #{{ milesCard.id }}
      </template>
      <a-tabs default-active-key="card">
        <a-tab-pane key="card" tab="Dados do Cartão" force-render>
          <a-row
            v-if="editMilesCardDrawer"
            class="view-data"
            :gutter="20"
            style="padding: 0px 10px"
          >
            <a-col :span="6">
              <div class="label">Cia aérea</div>
              <div class="value">
                {{ milesCard.airline.name }}
              </div>
            </a-col>

            <a-col :span="6">
              <div class="label">Programa de Milhas</div>
              <div class="value">
                {{ milesCard.miles_program }}
              </div>
            </a-col>

            <a-col :span="12">
              <div class="label">Fornecedor</div>
              <div class="value">
                {{ milesCard.supplier.first_name }}
                {{ milesCard.supplier.last_name }}
              </div>
            </a-col>

            <a-col :span="6">
              <div class="label">Usuário</div>
              <div class="value normal-font">
                {{ milesCard.user }}
              </div>
            </a-col>

            <a-col :span="6">
              <div class="label">Número</div>
              <div class="value">
                {{ milesCard.number }}
              </div>
            </a-col>

            <a-col :span="6">
              <div class="label">Senha</div>
              <div class="value">
                {{ milesCard.password }}
              </div>
            </a-col>

            <a-col :span="6">
              <div class="label">Senha de resgate</div>
              <div class="value normal-font">
                {{ milesCard.password_recovery }}
              </div>
            </a-col>

            <a-col :span="6">
              <div class="label">Quantidade de milhas</div>
              <div class="value">
                {{ milesCard.quantity }}
              </div>
            </a-col>

            <a-col :span="6">
              <div class="label">Valor (R$)</div>
              <div class="value">
                {{ milesCard.value }}
              </div>
            </a-col>
          </a-row>
        </a-tab-pane>
        <a-tab-pane key="supplier" tab="Fornecedor" force-render>
          <a-row
            v-if="editMilesCardDrawer"
            class="view-data"
            :gutter="20"
            style="padding: 0px 10px"
          >
            <a-col :span="6">
              <div class="label">ID</div>
              <div class="value">
                {{ milesCard.supplier.id }}
              </div>
            </a-col>
            <a-col :span="18">
              <div class="label">Nome do Fornecedor</div>
              <div class="value">
                {{ milesCard.supplier.first_name }}
                {{ milesCard.supplier.last_name }}
              </div>
            </a-col>
            <a-col :span="24">
              <div class="label">E-mail</div>
              <div class="value">
                {{ milesCard.supplier.email }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="label">Data de nascimento</div>
              <div class="value">
                {{ milesCard.supplier.birthday }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="label">CPF</div>
              <div class="value">
                {{ milesCard.supplier.cpf }}
              </div>
            </a-col>
            <a-col :span="8">
              <div class="label">RG</div>
              <div class="value">{{ milesCard.supplier.rg }}</div>
            </a-col>
            <a-col :span="8">
              <div class="label">Orgão emissor</div>
              <div class="value">
                {{ milesCard.supplier.rg_emissor }}
              </div>
            </a-col>
            <a-col :span="8">
              <div class="label">Estado</div>
              <div class="value">
                {{ milesCard.supplier.rg_state }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="label">Telefone fixo</div>
              <div class="value">
                {{ milesCard.supplier.phone }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="label">Celular</div>
              <div class="value">
                {{ milesCard.supplier.mobile_phone }}
              </div>
            </a-col>
            <a-col :span="24">
              <div class="label">Logradouro</div>
              <div class="value">
                {{ milesCard.supplier.location_street }}
              </div>
            </a-col>
            <a-col :span="8">
              <div class="label">Número</div>
              <div class="value">
                {{ milesCard.supplier.location_number }}
              </div>
            </a-col>
            <a-col :span="16">
              <div class="label">Complemento</div>
              <div class="value">
                {{ milesCard.supplier.location_complement }}
              </div>
            </a-col>
            <a-col :span="24">
              <div class="label">Bairro</div>
              <div class="value">
                {{ milesCard.supplier.location_neightborhood }}
              </div>
            </a-col>
            <a-col :span="8">
              <div class="label">Cidade</div>
              <div class="value">
                {{ milesCard.supplier.location_city }}
              </div>
            </a-col>
            <a-col :span="8">
              <div class="label">Estado</div>
              <div class="value">
                {{ milesCard.supplier.location_state }}
              </div>
            </a-col>
            <a-col :span="8">
              <div class="label">CEP</div>
              <div class="value">
                {{ milesCard.supplier.cep }}
              </div>
            </a-col>
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </a-drawer>

    <a-modal
      class="modal-special-price"
      width="430px"
      :visible="specialPriceModal"
      :footer="false"
      @cancel="specialPriceModal = false"
    >
      <template slot="title">
        <a-icon type="dollar" /> Preço Especial - ID {{ milesCard.id }}
        {{ milesCard.miles_program }}
      </template>

      <a-row :gutter="20">
        <a-col :span="12">
          <label> Preço especial </label>

          <div class="travel-input">
            <a-input
              class="special-price"
              v-currency
              v-model="specialPrice"
              placeholder="Insira o valor do preço especial"
            />
          </div>
        </a-col>
        <a-col :span="12">
          <a-button
            @click="updateSpecialPrice"
            class="mt-20 green"
            type="primary"
            style="width: 100%"
          >
            ATUALIZAR
          </a-button>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { format, parse } from "date-fns";
import MilesCardsModal from "@/components/miles/modal/MilesCardsModal.vue";
import milesCardsMixin from "@/mixins/miles-cards/milesCardsMixin";
import suppliersMixins from "@/mixins/suppliersMixins";
import formatThings from "@/mixins/general/formatThings";
import useGeneratePDF from "@/composables/generatePDF.js";

export default {
  components: { MilesCardsModal },
  name: "MilesCardsList",
  mixins: [milesCardsMixin, suppliersMixins, formatThings],
  beforeMount() {
    this.milesCards.pagination.perPage = 30;
    this.getMilesCards();
    this.getUsers();
    this.getAirlines();
    if (this.$store.state.viewMilesCard) {
      this.viewMilesCardDrawer = true;
      this.viewMilesCard(this.$store.state.theMilesCard.id, "card");
      this.milesCard.id = this.$store.state.theMilesCard.id;
    }
  },
  filters: {},
  setup() {
    const { generatePDF } = useGeneratePDF();
    return { generatePDF };
  },
  data() {
    return {
      loading: false,
      activeTab: "available",
      activeMilesCardTab: "",
      openFilters: 0,
      milesCard: {},
      viewMilesCardDrawer: false,
      editMilesCardDrawer: false,
      editMilesCard: false,
      editCard: {
        user: "",
        number: "",
        password: "",
        password_recovery: "",
        expires_at: "",
        loading: false,
      },
      specialPriceModal: false,
      excelFile: {
        header: "CONTROLE DE ESTOQUE",
        fileName: "CONTROLE DE ESTOQUE.xls",
        collumns: {
          ID: "id",
          VCM: {
            field: "transactions",
            callback: (transaction) => {
              let vcm = "";
              if (transaction.data.length > 0) {
                if (transaction.data[transaction.data.length - 1].is_vcm == 1) {
                  vcm = "Sim";
                } else {
                  vcm = "Não";
                }
              }
              return `${vcm}`;
            },
          },
          Fornecedor: {
            field: "supplier",
            callback: (supplier) => {
              return `${supplier.first_name} ${supplier.last_name}`;
            },
          },
          "Pgm. Milhas": "miles_program",
          "Total Milhas": "total_miles",
          Disponível: "available_miles",
          Utilizado: "spent_miles",
          "Valor/1000": "price_per_miles",
          "Preço Especial (R$)": "special_price",
          "Preço Venda": "selling_price",
          Validade: "expires_at",
          "Data de cadastro": "created",
        },
        data: [],
        footer: [],
      },
      pageSizeOptions: ["10", "25", "50", "100", "200", "500", "1000"],
      blockedMilesCardsList: [],
      specialPrice: "",
      totalMilesCards: 0,
      totalMiles: 0,
      totalAvailableMiles: 0,
      totalSpentMiles: 0,
      totalBlockedMiles: 0,
      totalMoney: 0,
      totalAvailableMoney: 0,
      totalMoneySpent: 0,
      totalBlockedMoney: 0,
      lastMilesCardsColumns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 60,
        },
        {
          title: "",
          width: 30,
          scopedSlots: { customRender: "is_vcm" },
        },
        {
          title: "Fornecedor",
          dataIndex: "supplier",
          key: "supplier",
          width: 160,
          scopedSlots: { customRender: "supplier" },
        },
        {
          title: "Pgm. Milhas",
          dataIndex: "miles_program",
          key: "miles_program",
        },
        {
          title: "Total Milhas",
          dataIndex: "total_miles",
          key: "total_miles",
          class: "total-miles",
          width: 140,
          sorter: true,
        },
        {
          title: "Disponível",
          dataIndex: "available_miles",
          key: "available_miles",
          class: "available-miles",
          sorter: true,
        },
        {
          title: "Utilizado",
          dataIndex: "spent_miles",
          key: "spent_miles",
          class: "spent-miles",
          sorter: true,
        },
        {
          title: "Valor/1000",
          dataIndex: "price_per_miles",
          key: "price_per_miles",
          scopedSlots: { customRender: "price_per_miles" },
        },
        {
          title: "Preço Especial",
          dataIndex: "special_price",
          key: "special_price",
          scopedSlots: { customRender: "special_price" },
        },
        {
          title: "Preço Venda ",
          dataIndex: "selling_price",
          key: "selling_price",
          scopedSlots: { customRender: "selling_price" },
          width: 130
        },
        {
          title: "Validade",
          dataIndex: "expires_at",
          key: "expires_at",
          scopedSlots: { customRender: "expires_at" },
          sorter: true,    width: 130
        },
        {
          title: "Data de cadastro",
          dataIndex: "created",
          key: "created",
          width: 160,
          scopedSlots: { customRender: "created" },
        },
        {
          title: "",
          align: "right",
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  beforeDestroy() {
    this.closeMilesCardDrawer();
  },
  methods: {
    onChangeTabs(tab) {
      console.log(tab);
      this.activeTab = tab;
      this.milesCards.pagination.page = 1;
      this.getMilesCards();
    },
    openSpecialPriceModal(milesCard) {
      this.milesCard = milesCard;
      this.specialPrice = milesCard.special_price;
      this.specialPriceModal = true;
    },
    updateSpecialPrice() {
      this.$http
        .post("/miles-card/update-field", {
          id: this.milesCard.id,
          field: "special_price",
          value: this.specialPrice.replace(",", "."),
          action: "special-price-milescard",
          description: `<font class="user">${
            this.$store.state.userData.first_name
          } ${
            this.$store.state.userData.last_name
          }</font> alterou o valor do Preço Especial do cartão <font class="id">ID ${
            this.milesCard.id
          } ${this.milesCard.miles_program}</font> para <font class="val">R$ ${
            this.specialPrice ? this.specialPrice : 0
          }</font>.`,
          modified_by: {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          },
        })
        .then(({ data }) => {
          this.$message.success(data.message);
          this.getMilesCards();
          this.specialPriceModal = false;
          this.editCard.loading = false;
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.editCard.loading = false;
        });
    },
    formatMiles(miles) {
      return miles;
    },
    formatPrice(price) {
      return price;
    },
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd", new Date());
      return format(theDate, "dd/MM/yyyy");
    },
    formatDateTime(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      return format(theDate, "dd/MM/yyyy HH:mm");
    },
    checkIsVcmMiles(transactions) {
      let isVcm = ``;

      if (transactions.data[transactions.meta.total - 1].is_vcm == "1") {
        isVcm = `<font class="corange" style="font-size: 24px; font-size: 24px;"> • </font>`;
      }

      return isVcm;
    },
    formatDateTimeAntd(date) {
      let theDate = parse(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", new Date());
      return format(theDate, "dd/MM/yyyy");
    },
    deleteMilesCard(id) {
      this.$http
        .post("/miles-card/delete", { id })
        .then(({ data: { message } }) => {
          this.$message.success(message);
          this.getMilesCards();
        })
        .catch(
          ({
            response: {
              data: { message },
            },
          }) => {
            this.$message.error(message);
          }
        );
    },
    getMilesCard(id, tab) {
      this.$http
        .post("/miles-card/details", {
          id,
        })
        .then(({ data }) => {
          this.milesCard = data;
          this.editCard = {
            user: data.user,
            number: data.number,
            password: data.password,
            password_recovery: data.password_recovery,
            expires_at: data.expires_at,
            loading: false,
          };
          this.viewMilesCardDrawer = true;
          this.activeMilesCardTab = tab;
        });
    },
    viewMilesCard(id, tab) {
      console.log(id, tab);
      this.getMilesCard(id, tab);
    },
    updateMilesCardsList() {
      this.milesCards.pagination.page = 1;
      this.getMilesCards();
    },
    view(id) {
      this.$router.push(`view/${id}`);
    },
    closeMilesCardDrawer() {
      this.viewMilesCardDrawer = false;
      this.editMilesCard = false;
      this.editMilesCardDrawer = false;
      this.$store.state.viewMilesCard = false;
      this.$store.state.theMilesCard = {};
      this.reasonForBlocking = undefined;
      this.getMilesCards();
    },
  },
};
</script>

<style scoped></style>
